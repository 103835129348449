import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Toolbar,
  TextField,
  AppBar,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar"; // Import the Sidebar component
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { CircularProgress } from "@mui/material";

const drawerWidth = 0; // Set to the width of the sidebar


const MainContainer = styled(Box)({
  display: "flex",
  marginLeft: `${drawerWidth}px`, // Adjust margin to accommodate the sidebar
  height: "100vh",
  backgroundColor: "#F5F5F5",
  overflow: "hidden",
});

const MainBox = styled(Box)({
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  height: "calc(100vh - 40px)",
  position: "relative",
  overflowY: "auto",
  width:'98%',
  
});

const Content = styled(Box)({
  flexGrow: 1,
  padding: "24px",
  backgroundColor: "#F5F5F5",
  height: "100%",
});

const formatText = (messageContent) => {
  if (Array.isArray(messageContent)) {
    return messageContent.map((item, index) => {
      if (item.type === 'text') {
        return (
          <Typography key={index} variant="body1" paragraph>
            {item.text}
          </Typography>
        );
      } else if (item.type === 'bullet_list') {
        return (
          <ul key={index} style={{ listStyleType: 'disc', paddingLeft: 20, margin: '10px 0' }}>
            {item.items.map((bulletItem, bulletIndex) => (
              <li key={bulletIndex}>
                <Typography variant="body1">{bulletItem.text}</Typography>
              </li>
            ))}
          </ul>
        );
      } else if (item.type === 'number_list') {
        return (
          <ol key={index} style={{ paddingLeft: 20, margin: '10px 0' }}>
            {item.items.map((numberItem, numberIndex) => (
              <li key={numberIndex} value={numberItem.number}>
                <Typography variant="body1">{numberItem.text}</Typography>
              </li>
            ))}
          </ol>
        );
      }
      return null;
    });
  } else if (typeof messageContent === 'string') {
    return messageContent.split("\n").map((section, index) => (
      <Typography key={index} variant="body1" paragraph>
        {section}
      </Typography>
    ));
  } else {
    return <Typography variant="body1">{String(messageContent)}</Typography>;
  }
};
const ElegantLoader = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    padding="10px"
    bgcolor="rgba(207, 232, 252, 0.9)"
    borderRadius="12px"
    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
    maxWidth="200px"
    margin="0 auto"
  >
    <CircularProgress size={20} style={{ marginRight: "10px" }} />
    <Typography variant="body2">Processing...</Typography>
  </Box>
);

const MainPage = () => {
  const isSmallScreen = useMediaQuery('(min-width:600px)')
  const [lastUploadedImage, setLastUploadedImage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const [isViewingHistory, setIsViewingHistory] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (location.state?.viewHistory) {
      const dummyHistory = [
        {
          sender: "user",
          text: "User message 1",
          timestamp: "2024-08-20T10:00:00",
        },
        {
          sender: "bot",
          text: "Bot response 1",
          timestamp: "2024-08-20T10:01:00",
        },
        {
          sender: "user",
          text: "User message 2",
          timestamp: "2024-08-20T10:05:00",
        },
        {
          sender: "bot",
          text: "Bot response 2",
          timestamp: "2024-08-20T10:06:00",
        },
      ];
      setMessages(dummyHistory);
      setIsViewingHistory(true);
    } else if (location.state?.text) {
      setMessages([
        { sender: "user", text: location.state.text },
        { sender: "bot", text: location.state.summary },
      ]);
    } else if (location.state?.file) {
      setMessages([
        {
          sender: "user",
          text: `Uploaded file: ${location.state.fileName}`,
          fileUrl: location.state.file,
        },
        { sender: "bot", text: location.state.summary },
      ]);
    }
  }, [location.state]);

  const handleNewChat = () => {
    setMessages([]);
    setIsViewingHistory(false);
    navigate("/", { state: { viewHistory: false } });
  };
  
   const handleUserInput = async () => {
    if (input.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: input },
      ]);
      addLoadingMessage();
      setIsLoading(true);
      setInput("");
      try {
        const response = await fetch("https://chat.aistrat.app/api/process_message/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: input,
            image_id: lastUploadedImage,
	    session_id: sessionId,
          }),
        });

        const data = await response.json();

        if (response.ok) {
           setMessages((prevMessages) => [
              ...prevMessages.filter(msg => msg.text !== "loading"),
          { sender: "bot", text: data.response },
        ]);
          setSessionId(data.session_id);
        } else {
          console.error("Error:", data.error);
          setMessages((prevMessages) => [
             ...prevMessages.filter(msg => msg.text !== "loading"),
          { sender: "bot", text: "Error: " + data.error },
        ]);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessages((prevMessages) => [
         ...prevMessages,
         { sender: "bot", text: "Error: Something went wrong" },
        ]);
      }finally {
        setIsLoading(false);
      }

   
      setInput("");
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: `Uploaded image: ${file.name}` },
      ]);
      addLoadingMessage();
      setIsLoading(true);
      try {
        const response = await fetch("https://chat.aistrat.app/api/process_image/", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (response.ok) {
          setMessages((prevMessages) => [
            ...prevMessages.filter(msg => msg.text !== "loading"),
            { sender: "bot", text: data.response },
          ]);
          setLastUploadedImage(data.image_id);
        } else {
          console.error("Error:", data.error);
          setMessages((prevMessages) => [
            ...prevMessages.filter(msg => msg.text !== "loading"),
            { sender: "bot", text: "Error: " + data.error },
          ]);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessages((prevMessages) => [
          ...prevMessages.filter(msg => msg.text !== "loading"),
          { sender: "bot", text: "Error: Something went wrong" },
        ]);
      }finally {
        setIsLoading(false);
      }
    }
  };
  const addLoadingMessage = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "bot", text: "loading" }
    ]);
  };
  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('session_id', sessionId);

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: `Uploaded PDF: ${file.name}` },
      ]);
      addLoadingMessage();
      setIsLoading(true);
      try {
        const response = await fetch("https://chat.aistrat.app/api/process_pdf/", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (response.ok) {
          setMessages((prevMessages) => [
            ...prevMessages.filter(msg => msg.text !== "loading"),
            { sender: "bot", text: data.response },
          ]);
	  setSessionId(data.session_id);
        } else {
          console.error("Error:", data.error);
          setMessages((prevMessages) => [
            ...prevMessages.filter(msg => msg.text !== "loading"),
            { sender: "bot", text: "Error: " + data.error },
          ]);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessages((prevMessages) => [
          ...prevMessages.filter(msg => msg.text !== "loading"),
          { sender: "bot", text: "Error: Something went wrong" },
        ]);
      }finally {
        setIsLoading(false);
      }
    }
  };


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleUserInput();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setInput((prevInput) => prevInput + "\n");
    }
  };

  return (
      <MainContainer sx={{width:isSmallScreen?'99%':'98%'}} >
     
      <Content>
        <Toolbar />
        <AppBar position="static" />
        <MainBox sx={{height:isSmallScreen?"calc(100vh - 40px)":"calc(100vh - 60px)"}}>
          <Button
            color="primary"
            onClick={handleNewChat}
            style={{
              position: "absolute",
              top:isSmallScreen?25:50,
              right:isSmallScreen?20 : 35,
              backgroundColor: "#4CAF50",
              color: "white",
              borderRadius: "8px",
              zIndex: 1,
              padding: "5px 10px",
            }}
          >
            New Chat
          </Button>
           <Box
            display="flex"
            flexDirection="column"
            mb={2}
           >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ paddingTop: isSmallScreen ? 2 : 5 }}
            >
              Chat With Your Document
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              (Powered by Claude.AI and AIGuru.academy)
            </Typography>
           </Box>
   
          <Box
            display="flex"
            flexDirection="column"
            style={{
              height: isSmallScreen?"calc(95vh - 180px)":"calc(86vh - 180px)",
              overflowY: "auto",
              marginTop: 20,
              padding: "10px 0",
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent={
                  message.sender === "user" ? "flex-end" : "flex-start"
                }
                mb={2}
              >
                <Paper
                  elevation={2}
                  style={{
                    padding: "10px 20px",
                    margin: "0 10px",
                    backgroundColor:
                      message.sender === "user" ? "#cfe8fc" : "#f0f0f0",
                    maxWidth: "80%",
                    borderRadius: "12px",
                  }}
                >
                  {message.timestamp && (
                    <Typography variant="caption" display="block" gutterBottom>
                      {new Date(message.timestamp).toLocaleString()}
                    </Typography>
                  )}
                  {message.fileUrl ? (
                    <>
                      <Typography variant="body1" paragraph>
                        {message.text}
                      </Typography>
                      <a
                        href={message.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Uploaded File
                      </a>
                    </>
                  ) : (
                    formatText(message.text)
                  )}
                </Paper>
              </Box>
            ))}
	    {isLoading && <ElegantLoader />}
            <div ref={messagesEndRef} />
          </Box>
          {!isViewingHistory && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              bottom={20}
              left="45%"
              style={{ transform: "translateX(-50%)", width: "90%" }}
            >
              <TextField
                variant="outlined"
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                style={{ marginRight: 10 }}
		multiline
                minRows={1}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="image-upload">
                <Button
                  component="span"
                  style={{ minWidth: "40px", padding: "10px" }}
                >
                  <ImageIcon />
                </Button>
              </label>
              <input
                accept=".pdf"
                style={{ display: "none" }}
                id="pdf-upload"
                type="file"
                onChange={handlePdfUpload}
              />
              <label htmlFor="pdf-upload">
                <Button
                  component="span"
                  style={{ minWidth: "40px", padding: "10px" }}
                >
                  <PictureAsPdfIcon />
                </Button>
              </label>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUserInput}
                style={{ padding: "14px 20px" }}
              >
                Send
              </Button>
            </Box>
          )}
        </MainBox>
      </Content>
    </MainContainer>
  );
};

export default MainPage;
