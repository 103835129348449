import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "#ACAEB4",
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000, // Ensuring it appears on top on small screens
    },
  },
}));

const Sidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleHistoryClick = () => {
    navigate("/main/chat", { state: { viewHistory: true } });
  };

  const handleContactusClick = () => {
    navigate("/cu")
  }

  const handleDashboardClick = () => {
    navigate("/main")
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#ACAEB4",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <DrawerStyled
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        variant={isSmallScreen ? "temporary" : "permanent"}
      >
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItem button key="History" onClick={handleHistoryClick}>
              <ListItemText primary="History" />
            </ListItem>
          
              <ListItem button key="Dashboard" onClick={handleDashboardClick}>
                <ListItemText primary="Dashboard" />
              </ListItem>
           
          </List>
          <List>
           
              <ListItem button key="Contact Us" onClick={handleContactusClick}>
                <ListItemText primary="Contact Us" />
              </ListItem>
          
          </List>
        </Box>
      </DrawerStyled>
    </>
  );
};

export default Sidebar;
